.contact{
    display: flex;
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: $red-500;
    color: $white;
}
.contact__content{
    display: flex;
    padding: 0;
    background-position: left top;
    background-size: auto 100% ;
    background-repeat: no-repeat;
    padding: 40px 0;
    justify-content: space-between;
}
.contact__title{
    font-size: 26px;
    line-height: 1.5;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    order: 2;
    color: $white;
    font-weight: 500;
    width: 100%;
}
.contact__wrap{
    order: 3;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 60px;
    width: 100%;
}
.contact__item{
    display: flex;
    align-items: center;
    padding: 0;
}
.contact__icon{
    height: 42px;
    width: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    border-radius: 50%;
    background-color: rgba(0,0,0,0.2);
    flex-shrink: 0;
}
.contact__item img{
    filter: invert(1) brightness(9);
    height: 14px;
    width: auto;
}
.contact__text{
    font-size: 16px;
    display: flex;
    flex-direction: column;
    font-weight: 500;
}
.contact__text small{
    font-size: 14px;
    font-weight: 400;
    color: $white;
} 
.contact__content{
    position: relative;
}

@media screen and (max-width: 991px){
    .contact__content{
        padding: 30px 0px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
    .contact__title{
        font-size: 24px;
        text-align: center;
        justify-content: center;
    }
    .contact__wrap{
        flex-grow: 1;
        flex-shrink: 0;
    }
    .contact__img{
        display: none;
    }
}
@media screen and (max-width: 575px){
    .contact{

    }
    .contact__content{
        padding: 30px 0px;
        flex-wrap: wrap;
    }
    .contact__title{
        font-size: 22px;
    }
    .contact__wrap{
        padding-left: 0px;
        display: flex;
        flex-direction: row;
        gap: 30px;
    }
    .contact__item{
        padding-right: 0px;
        padding-bottom: 0;
    }
}
@media screen and (max-width: 500px){
    .contact__title{
        font-size: 24px;
        padding-bottom: 0px;
        text-align: center;
    }
    .contact__wrap{
        justify-content: space-evenly;
    }
    .contact__content{
        padding: 30px 0px;
    }
    .contact__item{
        flex-direction: column;
        gap: 10px;
    }
    .contact__icon {
        margin-right:0px;
    }
}