.tag{
    font-size: 12px;
    font-weight: 400;
    background: $white;
    color: $gray-900;
    height: 22px;
    padding: 0 8px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3px;
    position: relative;
    border-radius: $border-radius-main;
}
.tag.--sale{
    /*background: $white;*/
    /*background-color: rgba($red-500,0.1);*/
    color: $red-500;
}
.tag.--new{
    /*background: $white;*/
    /*background-color: rgba($green-500,0.1);*/
    color: $green-500;
}
.tag.--recommended{
    /*background: $white;*/
    /*background-color: rgba($purple-500,0.1);*/
    color: $purple-500;
}
.tag.--top{
    background: $white;
    color: $gray-900;
}
.tag.--circleSale{
    background: $red-500;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    font-size: 16px;
    color: white;
    font-weight: 500;
}
.tag.--circle{
    background: #f7e517;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    font-size: 12px;
    color: black;
    font-weight: 500;
}
.tag.--free-delivery{
    background: $green-500;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    font-size: 13px;
    text-align: center;
    line-height: 1;
}



@media screen and (max-width:575px){
    .tag{
        font-size: 12px;
        font-weight: 400;
        height: 22px;
        padding: 0 6px;
        margin-bottom: 2px;
    }
}
