.section-news{
    padding: 80px 0;
}
.section-news__title{

}
.section-news__btnWrap{
    text-align: center;
    padding-top: 40px;
}
@media screen and (max-width: 991px){
    .section-news{
        padding: 60px 0;
    }
}