.products-sort{
    display: flex;
    background-color: $gray-100;
    margin-bottom: 20px;
    overflow: hidden;
    overflow-x: auto;
    -ms-overflow-style: none; 
    scrollbar-width: none; 
    border-radius: $border-radius-main;
    padding: 3px;
}
.products-sort::-webkit-scrollbar { 
    display: none;
}
.products-sort form{
    display: flex;

}
.products-sort__single{
    display: flex;
    position: relative;
    line-height: 1;
    cursor: pointer;
    flex-shrink: 0;
}

.products-sort__single input{
    opacity: 0;
    position: absolute;
    height: 0;
    width: 0;
}
.products-sort__single label{
    display: flex;
    font-weight: 400;
    padding: 17px 15px;
    color: $gray-500;
    cursor: pointer;
    font-size: 14px;
    border-radius: $border-radius-main;
}
.products-sort__single input:checked + label{
    color: $gray-900;
    background: $white;
}

@media screen and (max-width: 991px){

}