.page{

}
.page.--narrow{
    width: 100%;
    max-width: 820px;
    padding: 80px 110px 60px 110px;
    border: 1px solid $gray-300;
    margin: 60px auto 40px auto;
}
.page.--narrow .page-heading{
    padding-top: 0;
}
.page.--narrow .page-heading h1{
    text-align: center;
    padding-top: 0;
}
.page.--narrow .page-heading p{
    text-align: center;
    color: $gray-500;
    font-size: 14px;
    padding-top: 15px;
}

@media screen and (max-width: 991px){
    .page.--narrow{
        width: 100%;
        max-width: 100%;
        padding: 80px 60px 60px 60px;

    }
}
@media screen and (max-width: 767px){
    .page.--narrow{
        padding: 40px 20px 40px 20px;

    }
}
@media screen and (max-width: 575px){
    .page.--narrow{
        padding: 0;
        border: 0;
        margin-bottom: 0;
    }
}