footer{
    /*border-top: 1px solid $gray-300;*/
    background: $gray-900;
    padding: 80px 0 30px 0;
    content-visibility: auto;
    color: $white;
    font-size: 14px;
}
.footer__title{
    font-size: 15px;
    font-weight: 600;
    padding-bottom: 20px;
    color: $gray-400;
    text-transform: uppercase;
}
.footer__link{
    color: $white;
    line-height: 2;
    display: flex;
    font-size: 14px;
    font-weight: 300;
    &:hover{
        color: $white;
        text-decoration: underline;
    }
}
.footer__text{
    color: $white;
    line-height: 2;
    display: flex;
    margin: 0;
    font-size: 14px;
    font-weight: 300;
}
footer .copyright{
    padding-bottom: 0;
    margin-top: 80px;
    padding-top: 30px;
    border-top: 1px solid rgba(255,255,255,0.1);
}
.copyright a{
    color: $white;
}
.copyright__wrap{
    display: flex;
}
.copyright__title{
    flex-grow: 1;
}
.copyright__author{
    display: flex;
    align-items: center;
}
.copyright__author img{
    margin-left: 10px;
    margin-top: -8px;
    width: 54px;
    height: 22px;
    filter: invert(1) brightness(5);
}

@media screen and (max-width: 991px){
    footer {
        padding: 20px 0 20px 0;
    }
    .footer__title{
        padding: 40px 0 10px 0; 
    }
    .copyright {
        padding: 40px 0 0 0;
    }
}
@media screen and (max-width: 575px){
    footer {
        padding: 00px 0 20px 0;
    }
    .footer__title{
        font-size: 16px;
    }
    .footer__link,.footer__text{
        font-size: 14px;
    }
    .copyright{
        font-size: 14px;
    }
    .copyright__author img {
        width: 50px;
        height: 20px;
        margin-top: -10px;
    }
    footer .copyright{
        margin-top: 40px;
    }
}
@media screen and (max-width: 450px){
    .copyright__wrap {
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
    footer .copyright {
        padding-top: 20px;
    }
}