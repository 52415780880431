.social{
    padding-bottom: 60px;
    content-visibility: auto;
}
.social h1{
    padding-bottom: 30px;
}
.instagram-wrap{
    padding-bottom: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 20px;;
}
.instagram-single{
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s ease opacity;

    &:hover, &:active, &:focus{
        opacity: 0.8;
    }
}
.instagram-single img{
    width: 100%;
}
.social-single{
    padding: 10px 0;
}
.social-single__link{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $gray-300;
    padding: 20px 20px;
    color: $gray-900;    
    transition: 0.2s ease box-shadow;

    &:hover, &:active, &:focus{
        box-shadow: 0px 4px 8px rgba(0,0,0,0.08);
    }
}
.social-single__img{
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.social-single img{
    max-height: 100%;
}
.social-single__text{
    font-size: 16px;
    padding-left: 20px;
}
@media screen and (max-width: 575px){
    .social{
        padding-top: 0;
        padding-bottom: 20px;
    }
    .social h1 {
        padding-bottom: 10px;
    }
    .social-single{
        padding: 5px 0;
    }
    .social-single__link{
        padding: 20px 20px;
    }
    .social-single__img{
        height: 30px;
        width: 30px;

    }
    .social-single__text{
        font-size: 16px;
    }
    .instagram-wrap {
        padding-bottom: 10px;
    }
}