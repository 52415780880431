.points{
    padding-top: 0px;
    padding-bottom: 0px;
}
.points-content{
    background: $gray-100;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 20px;
    padding: 40px 40px;
    border-radius: $border-radius-main;
}
.points-single{
    display: flex;
    align-items: center;
}
.points-single__icon {
    content-visibility: auto;
    contain-intrinsic-size: 52px 52px;
}
.points-single__icon img{
    width: 52px;
    height: 52px;
    object-fit: contain;
}
.points-single__content{
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    font-size: 14px;
    color: $gray-900;
}
.points-single__content strong{
    font-weight: 400;
}
.points-single__content span{
    color: $gray-500;
}

@media screen and (max-width: 991px){
    .points-content{
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 20px;
    }
    .points{
        padding-top: 0;
    }
    .points-single{
        width: 100%;
    }
    .points-single__icon {
        contain-intrinsic-size: 50px 50px;
    }
    .points-single__icon img{
        width: 50px;
        height: 50px;
    } 
}
@media screen and (max-width:575px){
    .points-content{
        grid-template-columns: 1fr 1fr;
    }
    .points-single{
        padding: 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .points-single__content{
        padding: 0;
        padding-top: 10px;
    }
}
@media screen and (max-width:450px){
    .points-content{
        background: transparent;
        padding: 0;
        gap: 15px;
    }

}