#ratingSection {
    display: flex !important;
    flex-direction: column;
    position: relative;
    z-index: 1;
    background: 0 0;
    padding: 0px 0 0px 0;
    align-items: flex-start;
    background: white;

}
#ratingSection .rating-section-inner{
    padding: 80px 0 80px 0;
    border-top: 2px solid #f9f9f9;
    border-bottom: 0px solid #f9f9f9;
}
#ratingSection .rating-content {
    display: flex;
    align-items: center;
    margin-bottom: 40px
}

#ratingSection .rating-content-title {
    margin-left: 20px
}

#ratingSection .rating-content-text {
    display: flex;
    margin-top: 10px;
}

#ratingSectionCount {
    margin-right: 20px;
    padding-right: 20px;
    border-right: 1px solid #d9dfdd;
}

#ratingSection .rating-content-title h3 {
    font-size: 26px;
    margin-bottom: 0;
    width: auto;

}

#ratingSection .rating-content-title a {

    font-size: 15px;
    font-weight: 500;
    margin-top: 0
}

#ratingSection .rate-average-inner {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    background: $white;
    border: 1px solid $gray-300;
       padding: 15px;
    text-align: center;
    margin-bottom: 0
}

#ratingSection .stars-label {
    display: none
}

#ratingSectionCount .stars-label {
    display: flex
}

#ratingSection .vote-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px
}

#ratingSection .vote-wrap {
    border: 1px solid $gray-300;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
}

#ratingSection .vote-time {
    display: none
}

#ratingSection .vote-header {
    order:  2;
    margin-top: 20px;
    display: flex;
}

#ratingSection .vote-pic {
    height: 40px;
    width: 40px;
    background-color: $gray-100;
    background-image: url(../img/icons/user.svg);
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    margin-right: 12px;
    border-radius: 50%;
    flex-shrink: 0;
}

#ratingSection .vote-pic * {
    display: none
}

#ratingSection .vote-summary {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
}

#ratingSection .vote-summary::before {}

#ratingSection .rate-average {
    text-align: center;
    font-weight: 700;
    font-size: 28px;
    line-height: 30px;
   
    padding-bottom: 10px
}

#ratingSection .vote-name {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 15px;
    margin-bottom: 2px;
    font-weight: 500;
}
#ratingSection .vote-name span:last-child{
    color: $gray-500;
    font-weight: 400;
    font-size: 14px;
}
#ratingSection .stars{
    display: flex;
}
#ratingSection .stars .star {
    height: 13px;
    width: 13px;
    display: flex;
    background-size: 100%;
}

#ratingSection .vote-content {
    font-style: normal;
    color: #6d6c79;
    line-height: 1.5;
    margin-top: 15px;
    font-size: 14px;
    margin: 0;
}

@media screen and (max-width:991px) {
    #ratingSection {
        padding: 0px 0 0px
    }
    #ratingSection .rating-section-inner{
        padding: 60px 0 60px 0;
    }
    #ratingSection .vote-grid {
        display: flex;
        overflow: auto;
        width: 100%;
        grid-gap: 20px;
        padding-bottom: 0px;
    }

    #ratingSection .vote-wrap {
        min-width: 300px
    }
}

@media screen and (max-width:575px) {
    #ratingSection {
        padding: 0px 0;
    }
    #ratingSection .rating-section-inner{
        padding: 40px 0 40px 0;
    }
    #ratingSection .rating-content-title h3 {
        font-size: 20px;
        line-height: 1.2
    }

    #ratingSection .vote-content {
        line-height: 1.3
    }

    #ratingSection .rating-content-text {
        flex-direction: column
    }

    #ratingSectionCount {
        border: 0;
        margin: 0
    }
}

.vote-grid .vote-rating{
    display: flex;
    justify-content: space-between;
        width: 100%;
    align-items:center;
  }
  
  .vote-grid .vote-checked{
    font-size: 13px;
        color: $green-500;
    display: flex;
      align-items:center;
  }
  .vote-grid .vote-checked::before {
      content: "";
      background-size: 100%;
      height: 13px;
      width: 13px;
      display: flex;
      background-image: url(../img/icons/check.svg);
      margin-right: 5px;
  }

.page-content{
    .votepage-content{
        display: flex;
        margin-top: 40px;
        gap: 60px;
    }
    .votepage-summary{
        width: 400px;
        flex-shrink: 0;
        text-align: center;

    }
    .rate-average-wrap{
        background: $white;
        padding: 50px 40px;
        border: 1px solid $gray-300;
        position: sticky;
        top: 0;
    }
    .rate-star-wrap .stars{
        display: flex;
        justify-content: center;
    }
    .rate-average-wrap .rate-average {
        font-size: 36px;
        font-weight: 600;
        display: inline-flex;
        background: whitesmoke;
        border-radius: 50%;
        width: 80px;
        height: 80px;
        margin-bottom: 20px;
        justify-content: center;
        align-items: center;
        line-height: 1;
        padding: 0;
        color: $gray-500;
    }
    .rate-star-wrap .star {
        height: 30px;
        width: 30px;
    }
    .stars-label{
        padding-top: 10px;
    }
    .vote-grid{
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 0px;
        padding-top: 40px;
        border-top: 1px solid $gray-300;
        width: 100%;
        height: 100%;
    }
    .vote-wrap {
        background: #fff;
        /*box-shadow: 0px 4px 9px rgb(52 66 81 / 5%);*/
        padding: 0px 0px 40px 0;
        margin-bottom: 40px;
        display: flex;
        flex-direction: column;
        position: relative;
        border-radius: 0px;
        border-bottom: 1px solid $gray-300;
        width: 100%;
        height: auto;
    }
    .vote-header {
        order: 10;
        display: flex;
    }
    .vote-summary {
        display: flex;
        flex-direction: column;
        margin-left: 0px;
        line-height: 1;
        justify-content: center;
    }
    .vote-name {
        font-weight: 500;
        font-size: 15px;
        padding-bottom: 5px;
    }
    .vote-rating .stars {
        position: absolute;
        top: 0px;
        left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .vote-checked{
        color: $green-500;
        display: flex;
        line-height: 1;
        align-items: center;
        flex-grow: 1;
        justify-content: flex-end;
        font-size: 14px;
    }
    .vote-checked::before{
        content: "";
        background-size: 100%;
        height: 16px;
        width: 16px;
        display: flex;
        background-image: url(../img/icons/check.svg);
        margin-right: 5px;
    }

    .vote-time{
        color: $gray-500;
        font-size: 14px;
    }
    .vote-content {
        order: 0;
        margin-top: 50px;
        overflow: hidden;
        margin-bottom: 30px;
        font-size: 15px;
        line-height: 150%;
    }
    .stars .star {
        height: 20px;
        width: 20px;
        display: flex;
        background-image: url(../img/star-empty.svg);
        background-repeat: no-repeat;
        background-size: 100%;
        margin: 0px;
    }
    .vote-pic {
        height: 40px;
        width: 40px;
        background-color: $gray-100;
        background-image: url(../img/icons/user.svg);
        background-repeat: no-repeat;
        background-position: center center;
        display: flex;
        margin-right: 12px;
        border-radius: 50%;
        flex-shrink: 0;
    }
    .rate-graph{
        margin-top: 20px;
      }
      .rate-graph .rate-list {
          display: flex;
          align-items: center;
          padding: 5px 0;
      }
      .rate-graph .rate-value {
          width: 12px;
          text-align: center;
          font-weight: 500;
          color: $gray-500;
          padding-right: 5px;
      }
      .rate-graph .rate-star {
          display: flex;
        align-items:center;
      }
      .rate-graph .rate-block {
          display: flex;
          flex-grow: 1;
          background: $gray-100;
          height: 8px;
          margin-left: 15px;
      }
      .rate-graph .rate-list[data-score="5"] .rate-bar {
          background: #7bb82a;
      }
      .rate-graph .rate-list[data-score="4"] .rate-bar {
        background: #BAE637;
    }
    .rate-graph .rate-list[data-score="3"] .rate-bar {
        background: #D69E2E;
    }
    .rate-graph .rate-list[data-score="2"] .rate-bar {
        background: #ED8936;
    }
    .rate-graph .rate-list[data-score="1"] .rate-bar {
        background: #A8071A;
    }
      .rate-graph .rate-bar {
          display: flex;
      }
      .rate-graph .rate-count {
          display: none;
      }
      
     .rate-graph .stars .star{
        height: 14px;
        width: 14px;
      }
}
@media screen and (max-width:991px) {
    .page-content{
        .vote-grid{
            grid-template-columns: 1fr;
            grid-gap: 0px;
        }
        .votepage-content{
            gap: 40px;
        }
        .votepage-summary {
            width: 360px;
        }
    }
}
@media screen and (max-width:767px) {
    .page-content{
        .vote-grid{
            grid-template-columns: 1fr;
            grid-gap: 0px;
        }
        .votepage-content{
            flex-direction: column;
        }
        .votepage-summary {
            width: 100%;
        }
    }
}

.stars .star.star-on {
    background-image: url("../img/star-full.svg");
}
.stars .star.star-off {
    background-image: url("../img/star-empty.svg");
}




