$black: #000000;
$white: #FFFFFF;

$gray-100: #F7F7F8;
$gray-200: #F3F4F6;
$gray-300: #EFEFF1;
$gray-400: #8A8A8A;
$gray-500: #6e6e6e;
$gray-600: #6B7280;
$gray-700: #4B5563;
$gray-800: #313438;
$gray-900: #020203;
$gray-1000: #171717;

$red-100: #FFF5F5;
$red-200: #FED7D7;
$red-300: #FEB2B2;
$red-400: #FC8181;
$red-500: #D31217;
$red-600: #E53E3E;
$red-700: #C53030;
$red-800: #A8071A;
$red-900: #742A2A;

$orange-100: #FFFAF0;
$orange-200: #FEEBC8;
$orange-300: #FBD38D;
$orange-400: #F6AD55;
$orange-500: #ED8936;
$orange-600: #DD6B20;
$orange-700: #e39101;
$orange-800: #c77f01;
$orange-900: #7B341E;

$yellow-100: #FFFFF0;
$yellow-200: #FEFCBF;
$yellow-300: #FAF089;
$yellow-400: #F6E05E;
$yellow-500: #ECC94B;
$yellow-600: #D69E2E;
$yellow-700: #B7791F;
$yellow-800: #975A16;
$yellow-900: #744210;

$green-100: #ECFDF5;
$green-200: #D1FAE5;
$green-300: #A7F3D0;
$green-400: #6EE7B7;
$green-500: #50AC58;
$green-600: #3C9A45;
$green-700: #059669;
$green-800: #047857;
$green-900: #065F46;
$green-1000:#064E3B;

$teal-100: #E6FFFA;
$teal-200: #B2F5EA;
$teal-300: #81E6D9;
$teal-400: #4FD1C5;
$teal-500: #38B2AC;
$teal-600: #319795;
$teal-700: #2C7A7B;
$teal-800: #285E61;
$teal-900: #234E52;

$blue-100: #EBF8FF;
$blue-200: #BEE3F8;
$blue-300: #90CDF4;
$blue-400: #63B3ED;
$blue-500: #4299E1;
$blue-600: #3182CE;
$blue-700: #2B6CB0;
$blue-800: #2C5282;
$blue-900: #2A4365;

$indigo-100: #EBF4FF;
$indigo-200: #C3DAFE;
$indigo-300: #A3BFFA;
$indigo-400: #7F9CF5;
$indigo-500: #667EEA;
$indigo-600: #5A67D8;
$indigo-700: #4C51BF;
$indigo-800: #434190;
$indigo-900: #3C366B;

$purple-100: #FAF5FF;
$purple-200: #E9D8FD;
$purple-300: #D6BCFA;
$purple-400: #B794F4;
$purple-500: #652057;
$purple-600: #805AD5;
$purple-700: #6B46C1;
$purple-800: #553C9A;
$purple-900: #44337A;

$pink-100: #FFF5F7;
$pink-200: #FED7E2;
$pink-300: #FBB6CE;
$pink-400: #F687B3;
$pink-500: #ED64A6;
$pink-600: #D53F8C;
$pink-700: #B83280;
$pink-800: #97266D;
$pink-900: #702459;