.products-grid{
    display: grid;
    grid-template-columns: repeat(4,minmax(0,1fr));
    grid-gap: 20px;

}
.products-grid .product-card{
    width: 100%;
}
.products-count{
    color: $gray-500;
    font-size: 14px;
    padding-bottom: 20px;
}
@media screen and (max-width: 991px){
    .products-grid{
        grid-template-columns: repeat(2,minmax(0,1fr));
    }
}
@media screen and (max-width: 767px){
    .products-grid{
        grid-template-columns: repeat(2,minmax(0,1fr));
    }
    .products-grid .product-card{
        width: 100%;
    }
    .products-count{
        display: none;
    }
}
@media screen and (max-width: 575px){
    .products-grid{
        grid-template-columns: repeat(2,minmax(0,1fr));
        margin-left: 0px;
        margin-right: 0px;
        gap: 40px 20px;
    }
    .product-card__btn {
        display: none;
    }
}