.pagination{

}
.pagination__list{
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    margin: 0 -5px;
    align-items: center;
    justify-content: center;
}

.pagination__item{
    padding: 0 5px;
}
.pagination__item.--active .pagination__link{
    border: 1px solid $gray-300;
    color: $gray-900;
    border-radius: 50%;
}
.pagination__link{
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $gray-500;
}
